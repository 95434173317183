import { Navigate, createBrowserRouter } from 'react-router-dom';

// routes
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';

// project import
import ProfileRoutes from './ProfileRoutes';

// ==============================|| ROUTING RENDER ||============================== //

const router = createBrowserRouter(
  [
    { path: '/*', element: <Navigate to="/login" /> },
    LoginRoutes,
    MainRoutes,
    ProfileRoutes,
  ],
  {
    basename: process.env.REACT_APP_BASE_NAME,
  }
);

export default router;
