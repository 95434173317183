export default function componentStyleOverrides(
  theme,
  borderRadius
  // outlinedFilled
) {
  const mode = theme.palette.mode;
  // const bgColor = mode === 'dark' ? theme.palette.dark[800] : theme.palette.grey[50];
  const menuSelectedBack =
    mode === "dark"
      ? theme.palette.secondary.main + 15
      : theme.palette.secondary.light;
  const menuSelected =
    mode === "dark"
      ? theme.palette.secondary.main
      : theme.palette.secondary.dark;

  return {
    MuiButton: {
      styleOverrides: {
        root: {
          ...theme.typography.h4,
          borderRadius: '36px',
          padding: '14px 20px',
          boxShadow: 'none',
        },
        // contained: {
        //   backgroundColor: theme.palette.secondary.main,
        //   "&:hover": {
        //     backgroundColor: theme.palette.secondary.main,
        //   },
        // },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          // color: theme.palette.primary.main,
        },
        colorSecondary: {
          background: theme.palette.secondary.main,
          '&:hover': {
            background: theme.palette.secondary.dark,
          },
        },
      },
    },

    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
        rounded: {
          borderRadius: `${borderRadius}px`,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: theme.palette.text.dark,
          padding: '24px',
        },
        title: {
          fontSize: '1.125rem',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: 'center',
        },
        outlined: {
          border: '1px dashed',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
          paddingTop: '10px',
          paddingBottom: '10px',
          '&.Mui-selected': {
            color: menuSelected,
            backgroundColor: menuSelectedBack,
            '&:hover': {
              backgroundColor: menuSelectedBack,
            },
            '& .MuiListItemIcon-root': {
              color: menuSelected,
            },
          },
          '&:hover': {
            backgroundColor: menuSelectedBack,
            color: menuSelected,
            '& .MuiListItemIcon-root': {
              color: menuSelected,
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
          minWidth: '36px',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: theme.palette.text.dark,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.MuiInputBase-inputMultiline': {
            borderRadius: '10px !important',
          },
        },
        input: {
          color: theme.palette.text.dark,
          '&::placeholder': {
            color: 'rgba(35, 35, 34, 0.4)',
            fontSize: '16px',
            fontWeight: 400,
          },
          '&:-webkit-autofill': {
            WebkitTextFillColor: theme.palette.text.dark,
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // background: theme.palette.background.paper,
          borderRadius: '36px',
          backgroundColor: 'transparent',
          marginTop: theme.spacing(1),

          '& .MuiOutlinedInput-notchedOutline': {
            borderColor:
              mode === 'dark'
                ? theme.palette.text.primary + 28
                : theme.palette.grey[400],
          },
          '&:hover $notchedOutline': {
            borderColor: theme.palette.primary.light,
          },

          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.secondary.main,
          },

          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.secondary.main,
          },

          '& .MuiOutlinedInput-input:not(:placeholder-shown) + .MuiOutlinedInput-notchedOutline':
            {
              border: '1px solid #d0d0d0',
            },

          '&.MuiInputBase-multiline': {
            padding: 1,
          },
          '&.MuiInputBase-inputMultiline': {
            borderRadius: '10px !important',
          },
        },
        input: {
          fontWeight: 500,
          padding: '12px 16px',
          background: theme.palette.background.paper,
          borderRadius: '36px',
          '&.MuiInputBase-inputSizeSmall': {
            padding: '10px 14px',
            '&.MuiInputBase-inputAdornedStart': {
              paddingLeft: 0,
            },
          },
        },
        multiline: {
          borderRadius: '10px !important',

          '&.MuiInputBase-inputMultiline': {
            borderRadius: '10px !important',
          },
        },
        inputAdornedStart: {
          paddingLeft: 4,
        },
        notchedOutline: {
          borderRadius: '36px',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color:
              mode === 'dark'
                ? theme.palette.text.primary + 50
                : theme.palette.grey[300],
          },
        },
        mark: {
          backgroundColor: theme.palette.background.paper,
          width: '4px',
        },
        valueLabel: {
          color:
            mode === 'dark'
              ? theme.palette.primary.main
              : theme.palette.primary.light,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiAutocomplete-tag': {
            background:
              mode === 'dark'
                ? theme.palette.text.primary + 20
                : theme.palette.secondary.light,
            borderRadius: 4,
            color: theme.palette.text.dark,
            '.MuiChip-deleteIcon': {
              color:
                mode === 'dark'
                  ? theme.palette.text.primary + 80
                  : theme.palette.secondary[200],
            },
          },
          '& .MuiOutlinedInput-root': {
            padding: '5px',
          },
        },
        popper: {
          borderRadius: `${borderRadius}px`,
          boxShadow:
            '0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.divider,
          opacity: mode === 'dark' ? 0.2 : 1,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: theme.palette.grey[500],
          '&.Mui-checked': {
            color: theme.palette.secondary.main,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: theme.palette.grey[500],
          '&.Mui-checked': {
            color: theme.palette.secondary.main,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color:
            mode === 'dark'
              ? theme.palette.dark.main
              : theme.palette.primary.dark,
          background:
            mode === 'dark'
              ? theme.palette.text.primary
              : theme.palette.primary[200],
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '&.MuiChip-deletable .MuiChip-deleteIcon': {
            color: 'inherit',
          },
        },
      },
    },
    MuiTimelineContent: {
      styleOverrides: {
        root: {
          color: theme.palette.text.dark,
          fontSize: '16px',
        },
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        label: {
          marginTop: 14,
          marginBottom: 14,
        },
      },
    },
    MuiTimelineDot: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiInternalDateTimePickerTabs: {
      styleOverrides: {
        tabs: {
          backgroundColor:
            mode === 'dark'
              ? theme.palette.dark[900]
              : theme.palette.primary.light,
          '& .MuiTabs-flexContainer': {
            borderColor:
              mode === 'dark'
                ? theme.palette.text.primary + 20
                : theme.palette.primary[200],
          },
          '& .MuiTab-root': {
            color:
              mode === 'dark'
                ? theme.palette.text.secondary
                : theme.palette.grey[900],
          },
          '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.primary.dark,
          },
          '& .Mui-selected': {
            color: theme.palette.primary.dark,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          borderBottom: '1px solid',
          borderColor:
            mode === 'dark'
              ? theme.palette.text.primary + 20
              : theme.palette.grey[200],
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: '12px 0 12px 0',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '4px',
          fontSize: '14px',
          fontWeight: 500,

          color:
            mode === 'dark'
              ? theme.palette.grey[600]
              : theme.palette.grey.textColor,

          borderColor: '#2323221A',

          '&.MuiTableCell-head': {
            fontSize: '16px',
            fontWeight: 500,
            background: '#2323220D',
            color: '#23232280',
            borderBottom: '1px solid transparent',
            padding: '4px',

            '&:first-child': {
              borderTopLeftRadius: '12px',
              borderBottomLeftRadius: '12px',
            },

            '&:last-child': {
              borderTopRightRadius: '12px',
              borderBottomRightRadius: '12px',
            },
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:last-child td': {
            borderBottom: 0,
          },
          '&:hover': {
            // backgroundColor: theme.palette.secondary.light,
          },

          '&.Mui-selected': {
            // backgroundColor: theme.palette.secondary.light,
            backgroundColor: '#2323220D',
            '&:hover': {
              backgroundColor: theme.palette.secondary.light,
            },
          },
        },
      },
    },
    MuiDateTimePickerToolbar: {
      styleOverrides: {
        timeDigitsContainer: {
          alignItems: 'center',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: theme.palette.background.paper,
          background: theme.palette.text.primary,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1.25rem',
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          margin: '3px',
        },
      },
    },
  };
}
