import { Box, useTheme } from '@mui/material';
import logoSvg from 'assets/images/logo.svg';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'; // Import the admin icon

const Logo = () => {
  const theme = useTheme();
  const { authData } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const handleClick = () => {
    if (authData?.data?.token) {
      if (authData.data.userType.userTypeId === 3) {
        navigate('/a/project');
      }
    }
  };

  return (
    <Box display="flex" alignItems="center">
      <Box
        onClick={handleClick}
        component="img"
        src={logoSvg}
        alt="Prodculator"
        sx={{
          [theme.breakpoints.down('md')]: {
            width: '80%',
          },
          width: '80%', // Adjust width as needed
          height: 'auto', // Adjust height as needed
        }}
      />
      <AdminPanelSettingsIcon
        sx={{ color: 'action.active', ml: 2 }} // Adjust margin-left as needed
      />
    </Box>
  );
};

export default Logo;
