// material-ui
import { Avatar, Box, Stack, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project imports
import LAYOUT_CONST from 'constant';
import useConfig from 'hooks/useConfig';
import SearchSection from './SearchSection';

import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';
import LogoSection from 'ui-component/logoSection';
// assets
import AddIcon from '@mui/icons-material/Add';
import { IconMenu2 } from '@tabler/icons-react';
import { drawerWidth } from 'store/constant';
import DarkButton from 'ui-component/button/DarkButton';
import NotificationSection from './NotificationSection';
import { useNavigate } from 'react-router-dom';
import RedButton from 'ui-component/button/RedButton';
import { maxWidth } from '@mui/system';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menu);

  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const { layout } = useConfig();
  const user = useSelector((state) => state.auth.authData.data?.userType);

  const handleClick = () => {
    navigate('/project/add');
  };
  // const handleLogout = () => {
  //   navigate("/login");
  // };

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: drawerWidth,
          p: '24px 16px',
          display: 'flex',
          //   borderRight: '1px solid #2323221A',
          borderRight: { xs: 'none', md: '1px solid #2323221A' },
          [theme.breakpoints.down('md')]: {
            width: 'auto',
          },
        }}
      >
        <Box
          component="span"
          sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}
        >
          <LogoSection />
        </Box>

        {layout === LAYOUT_CONST.VERTICAL_LAYOUT ||
        (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd) ? (
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              display: { xs: 'flex', md: 'none' },
              overflow: 'hidden',
              transition: 'all .2s ease-in-out',
              background:
                theme.palette.mode === 'dark'
                  ? theme.palette.dark.main
                  : theme.palette.secondary.light,
              color:
                theme.palette.mode === 'dark'
                  ? theme.palette.secondary.main
                  : theme.palette.secondary.dark,
              '&:hover': {
                background:
                  theme.palette.mode === 'dark'
                    ? theme.palette.secondary.main
                    : theme.palette.secondary.dark,
                color:
                  theme.palette.mode === 'dark'
                    ? theme.palette.secondary.light
                    : theme.palette.secondary.light,
              },
            }}
            onClick={() => dispatch(openDrawer(!drawerOpen))}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="20px" />
          </Avatar>
        ) : null}
      </Box>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems={'center'}
        sx={{ flexGrow: 1, p: { xs: 1, md: 2 } }}
      >
        {/* header search */}
        <SearchSection />

        {/*  */}
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction={'row'} alignItems={'center'} gap={1.3}>
          <NotificationSection />
        </Stack>
      </Stack>
    </>
  );
};

export default Header;
